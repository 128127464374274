import { Action, createReducer, on } from '@ngrx/store';
import { get } from 'lodash';

import { IBillsCategory, IBillsDate } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  /**
   * Reservation bills reducers
   */
  on(fromActions.loadRequest, (state, { noLoading }) => ({
    ...state,
    loading: !noLoading,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { bills, filter }) => ({
    ...state,
    billsByCategory: (filter === 'default' && bills) as IBillsCategory,
    billsPerDate: (filter === 'date' && bills) as IBillsDate,
    loading: false,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * delete single bills reducers
   */
  on(fromActions.deleteBillRequest, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(
    fromActions.deleteBillSuccess,
    (state, { reservationBillId, addonId, addonDetailId }) => {
      if (!addonDetailId) {
        return {
          ...state,
          loading: false,
          error: null,
        };
      }
      const details = get(state, `billDetails.addon[${addonId}]`, {});
      let newDetail = null;
      Object.keys(details).forEach((detailId) => {
        let detailsAdd = details[detailId];
        if (+detailId === +addonDetailId) {
          detailsAdd = detailsAdd.filter(
            (bill) => bill.bill_id !== reservationBillId,
          );
        }
        if (detailsAdd.length) {
          newDetail = { ...newDetail, [detailId]: detailsAdd };
        }
      });
      return {
        ...state,
        loading: false,
      };
    },
  ),

  /**
   * set visibility single bill reducers
   */
  on(fromActions.setBillVisibilityRequest, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(fromActions.setBillVisibilitySuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(fromActions.setBillVisibilityFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * Load warnings
   */
  on(fromActions.loadBillsWarningsRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.loadBillsWarningsSuccess, (state, { warnings }) => ({
    ...state,
    warnings: warnings.bills_warnings?.length ? warnings : null,
    error: null,
  })),
  on(fromActions.loadBillsWarningsFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  /**
   * Load buyers
   */
  on(fromActions.loadBuyersRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.loadBuyersSuccess, (state, { allBillsCustomers }) => ({
    ...state,
    allBillsCustomers: allBillsCustomers
      .filter((customer) => !!customer)
      .map((customer) => ({
        ...customer,
        full_name:
          customer.category_id === 4
            ? customer.surname
            : `${customer.name} ${customer.surname}`,
      })),
    error: null,
  })),
  on(fromActions.loadBuyersFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  /**
   * Search bills
   */
  on(fromActions.searchBillsRequest, (state) => ({
    ...state,
    loadingSearch: true,
    error: null,
  })),
  on(fromActions.searchBillsSuccess, (state, { billsSearchResults }) => ({
    ...state,
    billsSearchResults,
    loadingSearch: false,
    error: null,
  })),
  on(fromActions.searchBillsFailure, (state, { error }) => ({
    ...state,
    loadingSearch: false,
    error,
  })),
  on(fromActions.resetSearchBillsResults, (state) => ({
    ...state,
    billsSearchResults: null,
  })),

  /**
   * Reservation details bills reducers
   */
  on(fromActions.resetState, (state) => ({
    ...fromState.initialState,
    loading: state.loading,
    warnings: state.warnings,
  })),
);

export function reservationDetailsBillsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
