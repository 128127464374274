import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  BillSearchOption,
  CustomerLookup,
  IBillsCategory,
  IBillsDate,
  IBillsRef,
} from '../../models';
import { ReservationBillsWarnings } from '../../models/objects/reservation-bills-warning';

import { State } from './state';

/**
 * State selector
 */
export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('reservationDetailsBillsStore');

/**
 * Errors selector
 */
export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  (state: State) => state.error,
);

/**
 * Reservation bills per date selector
 */
export const selectReservationBillsPerDate: MemoizedSelector<
  object,
  IBillsDate
> = createSelector(selectState, (state: State) => state.billsPerDate);

/**
 * Reservation bills by category selector
 */
export const selectReservationBillsByCategory: MemoizedSelector<
  object,
  IBillsCategory
> = createSelector(selectState, (state: State) => state.billsByCategory);

/**
 * Reservation bills by category selector
 */
export const selectWarnings: MemoizedSelector<
  object,
  ReservationBillsWarnings
> = createSelector(selectState, (state: State) => state.warnings);

/**
 * Bills search results
 */
export const selectSearchResults: MemoizedSelector<object, BillSearchOption[]> =
  createSelector(selectState, (state: State) => state.billsSearchResults);

/**
 * Reservation bills all customers selector
 */
export const selectAllCustomers: MemoizedSelector<object, CustomerLookup[]> =
  createSelector(selectState, (state: State) => state.allBillsCustomers);

/**
 * Reservation bills loadign selector
 */
export const selectReservationBillLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, (state: State): boolean => state.loading);

/**
 *  Bills search loading
 */
export const selectReservationBillsSearchLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectState, (state: State): boolean => state.loadingSearch);

export const selectReservationBills = createSelector(
  selectReservationBillsByCategory,
  (result) => {
    const { addons } = result || {};

    if (!addons) {
      return [];
    }

    return Object.values(addons).reduce(
      (extraServices: IBillsRef[], addonValue) => {
        if (typeof addonValue === 'object' && 'refs' in addonValue) {
          extraServices = [...extraServices, ...addonValue.refs];
        }

        return extraServices;
      },
      [],
    );
  },
);
