import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { effectHooks } from '../../helpers';
import { BillSearchOption, CustomerLookup } from '../../models';
import { ReservationBillsWarnings } from '../../models/objects/reservation-bills-warning';
import { ReservationDetailsBillsService } from '../../services/reservation-details-bills.service';
import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ReservationDetailsBillsStoreEffects {
  constructor(
    private dataService: ReservationDetailsBillsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
    private warningModalService: WarningConfirmModalService,
  ) {}
  /** Carico gli addebiti filtrati per categoria */
  loadBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      flatMap(({ reservationId, params }) =>
        this.dataService.loadBills(reservationId, params).pipe(
          map(({ data }: IResponseSuccess) => {
            return fromActions.loadSuccess({
              bills: data,
              filter: params.type as 'default' | 'date',
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  /** Elimino l'addebito di una prenotazione */
  deleteBillRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteBillRequest),
      flatMap(
        ({
          reservationId,
          reservationBillIds,
          addonId,
          addonDetailId,
          invoiceId,
        }) =>
          this.dataService.deleteBill(reservationId, reservationBillIds).pipe(
            map(({ meta }: IResponseSuccess) => {
              if (meta.warnings?.delete) {
                uniq(Object.values(meta.warnings.delete)).forEach(
                  (warning: string) => this.notifications.warning(warning),
                );
              } else {
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    'notifications.delete_success',
                    {
                      param: this.translate.instant('reservation_charge'),
                    },
                  ),
                  type: 'success',
                });
              }

              return fromActions.deleteBillSuccess({
                reservationBillId: reservationBillIds[0],
                addonId,
                addonDetailId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.deleteBillFailure(error));
            }),
          ),
      ),
    ),
  );

  /** modifica visibilità addebiti di una prenotazione */
  setBillVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setBillVisibilityRequest),
      flatMap(
        ({ reservationId, billId, visible, referenece_type, referenece_id }) =>
          this.dataService
            .setBillVisibility({ reservationId, billId, visible })
            .pipe(
              map((_) => {
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    'notifications.update_success',
                    {
                      param: this.translate.instant('reservation_charge'),
                    },
                  ),
                  type: 'success',
                });
                return fromActions.setBillVisibilitySuccess({
                  referenece_type,
                  referenece_id,
                });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.setBillVisibilityFailure(error));
              }),
            ),
      ),
    ),
  );

  /** sposta addebiti verso un'altra reservation_accommodation */
  moveBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveBillsRequest),
      flatMap(({ type, origin, onSuccess, onFailure, ...payload }) =>
        this.dataService.moveBills({ ...payload, type: origin }).pipe(
          map((_) => {
            if (onSuccess) {
              onSuccess();
            }

            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('bills_moved_success'),
              type: 'success',
            });

            return fromActions.moveBillsSuccess();
          }),
          catchError((error) => {
            if (onFailure) {
              onFailure();
            }

            this.errorHandler.handle(error);
            return of(fromActions.moveBillsFailure(error));
          }),
        ),
      ),
    ),
  );

  /** Apre la modale per confermare  l'operazione in caso di warnings */
  openWanringsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openWarningsModal),
        map(({ message, bill_restore_id }) => {
          const action = fromActions.restoreMovedBillsRequest({
            bill_restore_id,
            force_operation: true,
            success_message: this.translate.instant(
              'notifications.generic_success',
            ),
          });

          const discardAction = fromActions.restoreMovedBillsFailure({
            error: null,
          });

          this.warningModalService.open({
            message,
            action,
            discardAction,
            labelAction: 'yes',
            labelDiscardAction: 'no',
          });
        }),
      ),
    { dispatch: false },
  );

  /** ripristina addebiti spostati verso un'altra reservation_accommodation */
  restoreMovedBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.restoreMovedBillsRequest),
      flatMap(({ bill_restore_id, force_operation, success_message }) =>
        this.dataService
          .restoreMovedBills(bill_restore_id, force_operation)
          .pipe(
            map(
              ({
                meta: { warnings, confirm_required },
              }: IResponseSuccess<{}>) => {
                if (confirm_required) {
                  return fromActions.openWarningsModal({
                    bill_restore_id,
                    message: warnings.toString(),
                  });
                }

                if (warnings?.length) {
                  warnings.forEach((warning) =>
                    this.notifications.warning(warning),
                  );
                  return fromActions.restoreMovedBillsFailure({
                    error: warnings,
                  });
                }

                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    success_message || 'bills_moved_success',
                  ),
                  type: 'success',
                });
                return fromActions.restoreMovedBillsSuccess();
              },
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.restoreMovedBillsFailure(error));
            }),
          ),
      ),
    ),
  );

  /** splitta addebiti verso un'altra reservation_accommodation o conto passante */
  splitBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.splitBillsRequest),
      flatMap(({ type, bill_id, hooks, ...payload }) =>
        this.dataService.splitBills(bill_id, payload).pipe(
          effectHooks({ ...hooks }),
          map(({ meta }: IResponseSuccess) => {
            if (meta.warnings) {
              meta.warnings.forEach((warning: string) =>
                this.notifications.warning(warning),
              );
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant('bill_subdivide_success'),
                type: 'success',
              });
            }

            return fromActions.splitBillsSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.splitBillsFailure(error));
          }),
        ),
      ),
    ),
  );

  splitBillsBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.splitBillsBulkRequest),
      flatMap(({ request, hooks }) => {
        const { onSuccess, onFailure } = hooks;
        return this.dataService.splitBillsBulk(request).pipe(
          map(({ meta }: IResponseSuccess) => {
            if (onSuccess) {
              onSuccess();
            }

            if (meta.warnings) {
              meta.warnings.forEach((warning: string) =>
                this.notifications.warning(warning),
              );
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant('bill_subdivide_success'),
                type: 'success',
              });
            }

            return fromActions.splitBillsBulkSuccess();
          }),
          catchError((error) => {
            if (onFailure) {
              onFailure();
            }

            this.errorHandler.handle(error);
            return of(fromActions.splitBillsBulkFailure(error));
          }),
        );
      }),
    ),
  );

  /** carica i warnings sugli addebiti causati da modifiche dei portali */
  loadBillsWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBillsWarningsRequest),
      switchMap(({ reservation_id }) => {
        return this.dataService.loadWarnings(reservation_id).pipe(
          map(
            ({
              data: warnings,
            }: IResponseSuccess<ReservationBillsWarnings>) => {
              return fromActions.loadBillsWarningsSuccess({ warnings });
            },
          ),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadBillsWarningsFailure({ error }));
          }),
        );
      }),
    ),
  );

  /** cerca addebiti di una prenotazione */
  searchBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.searchBillsRequest),
      switchMap(({ reservation_id, label, filters }) => {
        return this.dataService
          .searchBills(reservation_id, label, filters)
          .pipe(
            map(
              ({
                data: billsSearchResults,
              }: IResponseSuccess<BillSearchOption[]>) => {
                return fromActions.searchBillsSuccess({ billsSearchResults });
              },
            ),
            catchError((error: any) => {
              this.errorHandler.handle(error);
              return of(fromActions.searchBillsFailure({ error }));
            }),
          );
      }),
    ),
  );

  /** modifica gli addebiti di una prenotazione */
  editBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editBillsRequest),
      switchMap(({ reservationId, bills, onSuccess }) => {
        return this.dataService.editBills(reservationId, bills).pipe(
          effectHooks({ onSuccess }),
          map((response: IResponseSuccess) => {
            if (!this.errorHandler.handleWarnings(response)) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('reservation_charge'),
                  },
                ),
                type: 'success',
              });
            }

            return fromActions.editBillsSuccess();
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(fromActions.editBillsFailure({ error }));
          }),
        );
      }),
    ),
  );

  /** carica paganti di una prenotazione */
  loadBuyers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBuyersRequest),
      switchMap(({ reservationId, params }) => {
        return this.dataService.loadBuyers(reservationId, params).pipe(
          map(
            ({
              data: allBillsCustomers,
            }: IResponseSuccess<CustomerLookup[]>) => {
              return fromActions.loadBuyersSuccess({ allBillsCustomers });
            },
          ),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadBuyersFailure({ error }));
          }),
        );
      }),
    ),
  );
}
