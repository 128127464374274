import {
  BillSearchOption,
  CustomerLookup,
  IBillsCategory,
  IBillsDate,
} from '../../models';
import { ReservationBillsWarnings } from '../../models/objects/reservation-bills-warning';

export interface State {
  loading: boolean;
  loadingSearch: boolean;
  billsPerDate: IBillsDate;
  billsByCategory: IBillsCategory;
  warnings: ReservationBillsWarnings;
  allBillsCustomers: CustomerLookup[];
  billsSearchResults: BillSearchOption[];
  error: any;
}

export const initialState: State = {
  loading: false,
  loadingSearch: false,
  billsPerDate: null,
  billsByCategory: null,
  warnings: null,
  allBillsCustomers: null,
  billsSearchResults: null,
  error: null,
};
